import React from 'react';

const HomePage = () => (
  <div className="bg-primary text-light min-h-screen flex flex-col">
    <div className="flex flex-col items-center justify-center h-screen bg-primary text-light font-sans">
      <h1 className="text-[54px] sm:text-[70px] font-bodoni font-bold">VAGUE.DEV</h1>
      <p className="text-[14px] sm:text-[19px] font-garet mt-2">
        where undefined becomes refined
      </p>
    </div>
    <div className="h-screen flex flex-col">
      <section className="flex-grow flex flex-col items-center justify-center text-center">
        <div className="p-4 max-w-4xl">
          <div className="relative p-8">
            {/* Top-Left Border */}
            <div className="absolute top-0 left-0 w-12 h-12 border-t-4 border-l-4 border-light"></div>

            {/* Bottom-Right Border */}
            <div className="absolute bottom-0 right-0 w-12 h-12 border-b-4 border-r-4 border-light"></div>

            <p className="text-[14px] sm:text-[19px] font-garet">
              At Vague, we specialize in creating innovative, custom software solutions that transform undefined problems into refined, actionable outcomes. Whether it's developing apps, websites, or even robots, we take our clients' challenges and ideas and turn them into the tools they need to succeed. Our expertise extends beyond software, sometimes integrating with physical hardware to deliver truly unique and impactful solutions. Alongside client work, we also develop and publish in-house projects, driven by our passion for exploring bold, unconventional ideas. At Vague, we're where undefined becomes refined.
            </p>
          </div>
        </div>
        {/* Trusted By Section */}
        <div className="mt-8">
          <h3 className="text-[16px] sm:text-[20px] font-garet mb-4">Trusted by</h3>
          <div className="flex justify-center gap-8">
            <a href="https://firethornpartners.com" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/firethornlogo.png"
                alt="Firethorn Logo"
                className="h-12 sm:h-24 hover:opacity-80"
              />
            </a>
            <a href="https://www.globalexpeditionsgroup.com/" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/gxglogo.png"
                alt="GXG Logo"
                className="h-12 sm:h-24 hover:opacity-80"
              />
            </a>
          </div>
        </div>
      </section>
      <footer className="text-[12px] sm:text-[17px] text-center py-4 bg-primary">
        <a
          href="mailto:info@vague.dev"
          className="text-white font-garet no-underline hover:underline"
        >
          info@vague.dev
        </a>
      </footer>
    </div>
  </div>
);

export default HomePage;
